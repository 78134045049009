/**
 * Video
 *
 * Affichage d'une lightbox video
 */
require('lity');

$(document).ready(function(){


});
